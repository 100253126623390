import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Data Monetization Strategy",
      desc: "Data monetization strategy involves specialized services that help organizations maximize the value of their data. The process involves the assessment of data assets, the identification of monetization opportunities, and the development of strategies for generating revenue or other forms of value from data. This includes also the navigation of data privacy regulations, advise on technology and pricing, and provide ongoing support to optimize data monetization efforts.",
      icon: "fas fa-money-bill-alt",
    },
    {
      name: "Leading Data Teams",
      desc: "Consultancy services for leading data science teams involve specialized support to optimize the performance and effectiveness of data science units within organizations. These services include talent acquisition and development, data strategy formulation, project management, and advanced analytics training. Consultants work closely with data science leaders to enhance team capabilities, streamline processes, and drive data-driven decision-making for improved business outcomes..",
      icon: "fas fa-compress-arrows-alt",
    },
    {
      name: "Data-driven decision making",
      desc: "Data-driven decision making is an approach that relies on data and analysis, rather than intuition, to inform and guide decision-making processes. It involves collecting, processing, and analyzing data to extract valuable insights, which are then used to make informed choices in various domains, from business strategy to healthcare. This method helps reduce biases, enhance decision quality, and adapt strategies to changing circumstances, ultimately leading to more effective and informed decisions.",
      icon: "fas fa-screwdriver",
    },
    {
      name: "Process automation with AI",
      desc: "Process automation with AI involves a systematic approach to streamline and optimize business processes. It begins with identifying suitable processes, collecting and preparing data, selecting and training AI models, and validating their performance. Deployment into the existing workflow, continuous monitoring, feedback integration, and maintaining security and compliance are key steps. Regular updates and documentation, as well as collaboration between teams, ensure the long-term success of AI-driven process automation, leading to improved operational efficiency and cost reduction.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Business Intelligence Suit",
      desc: "A Business Intelligence (BI) Suite is a comprehensive software solution that empowers organizations to collect, analyze, and visualize their business data for better decision-making. These suites offer a wide range of tools and features, including data integration, transformation, analytics, visualization, reporting, and more. They enable businesses to transform data into actionable insights and enhance performance monitoring, making them valuable assets for informed decision-makers in various roles within an organization.",
      icon: "fas fa-chart-area",
    },
    {
      name: "MLOPS strategy",
      desc: "An MLOps strategy, or Machine Learning Operations strategy, is a comprehensive plan for efficiently managing the entire lifecycle of machine learning models. It combines principles from DevOps and data science to automate processes like model development, deployment, monitoring, and version control. MLOps strategies emphasize continuous integration and deployment, security, compliance, documentation, and collaboration to streamline machine learning operations and ensure models align with business goals.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "ML model development & Lifecycle management",
      desc: "ML model development and lifecycle management is a systematic process that involves defining, developing, deploying, monitoring, and maintaining machine learning models. It begins with defining the problem, collecting and preparing data, and selecting an appropriate model. After training and evaluating the model, it's deployed into production, continuously monitored, and maintained. Throughout its lifecycle, considerations include scalability, security, compliance, version control, documentation, and eventually, model retirement and replacement. Effective management of this lifecycle is essential for long-term success in machine learning applications.",
      icon: "fas fa-toolbox",
    },
    {
      name: "Customer Analytics & Optimisation Suit",
      desc: "A Customer Analytics & Optimization Suite is a software solution designed to help businesses understand and improve their interactions with customers. It includes tools for segmenting customers, analyzing data, creating customer profiles, making personalized recommendations, and conducting A/B testing. These suites enable data-driven decision-making and enhance customer engagement, satisfaction, and retention, making them valuable for businesses seeking to optimize their operations and marketing efforts.",
      icon: "fa fa-suitcase",
    },
    {
      name: "Automated Data Extraction",
      desc: "An Automated Data Extraction Engine is a software system that extracts specific information from unstructured or semi-structured sources, like documents or web pages. It works by identifying the data source, ingesting the data, recognizing patterns, extracting relevant information, validating it, structuring it, and presenting it in a usable format. These engines are valuable for businesses, automating data processing tasks, and reducing human error in data extraction. They are widely used in various industries.",
      icon: "fa fa-file-text",
    },
    
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
