import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {

  const experienceDetails = [
    {
      yearRange: "2023 - current",
      title: "Owner",
      place: "AIMONETIZE B.V.",
      desc: "Our mission is to make AI accessible and impactful for businesses of all sizes. We offer a range of AI solutions and services tailored to meet the unique needs of our clients. Whether you are looking to optimize your operations, enhance customer experiences, or develop cutting-edge AI products, we have the expertise to help you achieve your goals. Key offerings: AI Consulting, AI Development, Data Analytics, AI Training and Workshops, AI Integration" 
    },
    {
      yearRange: "2022 - 2023",
      title: "Lead Data Scientist",
      place: "Atradius, Credit Insurance",
      desc: "Responsible for applying advanced statistical analysis tools and ML techniques for a different number of business challenges e.g., churn prediction, fraud detection, natural language processing, risk analysis, customer life value, customer segmentation and robotic automation. Apart from the technical aspect, a big part of the responsibility included giving direction, advise and coach junior colleagues, being the AI evangelist, building Proof of Concept/Values to kick start projects, coaching the internal team and co-building with the other leaders the AI strategy for the organization."
    },
    {
      yearRange: "2019 - 2022",
      title: "AI Lead CEE",
      place: "CGI, Consulting",
      desc: "Managing the AI team, aligning with the AI teams in the CEE unit of CGI, planning projects, building analytics models, and offering consultancy on AI innovative projects across the CGI portfolio. Interpret, and analyze data problems, build analytic systems and predictive models. Test performance of data-driven products. Couching and training junior members, supporting not technical members of the organization with cutting-edge data expertise where needed to support the pitching of projects to new and/ or existing customers."
    },
    {
      yearRange: "2019 - current",
      title: "Owner",
      place: "Straight From The Grooves - Music Event Planning & Promotion",
      desc: "At Straight From The Grooves, we are the heartbeat of unforgettable musical experiences. As a premier music event organizer and promoter, we are dedicated to curating, planning, and promoting exceptional music events that resonate with the soul. Our passion for music is at the core of everything we do. We bring together artists, fans, and the stage to create harmonious events that leave a lasting imprint." 
    },
    {
      yearRange: "2015 - 2020",
      title: "Owner - Brew Master",
      place: "The Goat Brewery Rotterdam",
      desc: "The Goat Beer Brewing Rotterdam, founded in 2015, was a vibrant ode to the spirit of Rotterdam and the love of craft brewing, all brought to life by a group of close-knit friends. Known for their distinct beers and playful goat-themed branding, the brewery quickly became a local gem. Unfortunately, in 2020, the COVID-19 pandemic forced the closure of this beloved establishment. Though the brewery is no longer operational, the memories and camaraderie forged during those five years continue to be celebrated, and we look forward to a future where the spirit of The Goat Beer Brewing Rotterdam may once again thrive."
    },
    {
      yearRange: "2018 - 2019",
      title: "Senior Data Scientist, Instant Mortgage Platform",
      place: "ING, Financial Services",
      desc: "The position breathes in the heart of the ING Labs, ING's Innovation Center, in an initiative related to the Payment Services Directive 2 (PSD2), a major regulatory change that will impact traditional financial and fintech companies and change banking forever. My role is to solve a wide variety of real-world business problems using big data, advanced machine learning techniques and the latest tech stuck available. The target is to extract knowledge out of a complex data and regulatory environment and create accurate predictions that drive the automation and intelligent decision making of our digital products. The assignment was focused in creating first Europe's Instant mortgage platform",
    },
    {
      yearRange: "2016 - 2017",
      title: "Senior Data Scientist in Digital Data Team",
      place: "TNT-Fedex, Logistics",
      desc: "TNT Digital was created in 2015 and had set the targets high in terms of team’s responsibilities and performance. The opportunities that were opening ahead were immense. The target is to create an online SME service that is so easy to use that it makes TNT the preferred shipping supplier for senders and receivers. Create state of the art analytical and predictive models to understand the digital customers behavior and increase retention, customer life value and user experience utilizing big data, machine learning and digital frameworks. Setting up with the Software engineers the big data solution in AWS and a set of data democratization initiatives to make intelligence through data available within the digital department"},
    {
      yearRange: "2013 - 2016",
      title: "Data Scientist, Customer Data & Analytics",
      place: "Sanoma, Media",
      desc: "During a period of media disruption from big platforms like Google and Facebook, Sanoma started creating the department of Customer Data & Analytics as a cross-country and cross-functional instrument that will facilitate the digital transformation of the company on all products and channels. As one of the first data scientists in this team, my role was to support the various business functions and products like magazines and websites, with customer-centric and data-driven analytical solutions based on big data and a rich consensus of the customer base. Very dynamic multiverse role within which I changed many hats and worked with virtually every department advocating on big data capabilities and digital innovation with pragmatic results.",
    },
    {
      yearRange: "2012 - 2013",
      title: "Reporting Executive in Western Europe Region",
      place: "British American Tobacco, Tobacco industry",
      desc: "This role covers the responsibility of Western Europe Region. Being in the regional office requires advanced skills in managing multiple stakeholders, strong collaboration and team working across region, focus on more general business and significant level of flexibility and open minded attitude"
    },
    {
      yearRange: "2012 - 2012",
      title: "Technical Consultant in Analytics & Shopper Knowledge ",
      place: "Symphony IRI, Consulting",
      desc: "Transaction, optimization and integration into SIG of key analysis processes which are currently outsourced. Development of new market’s panel data processes yielding into brand new analysis modules delivered to our clients."
    },
    {
      yearRange: "2010 - 2012",
      title: "Business Analyst in Analytics & Shopper Knowledge",
      place: "Symphony IRI, Consulting",
      desc: "Transaction, optimization and integration into SIG of key analysis processes which are currently outsourced. Development of new market’s panel data processes yielding into brand new analysis modules delivered to our clients."
    },
    {
      yearRange: "2008 - 2009",
      title: "Intern Econometric Analyst, Consumer Products Goods(CPG)",
      place: "SKIM Group, Consulting in Market Research",
      desc: "Responsible for the implementation of the structural development, econometric analysis, and report of the results to the senior management regarding two Price Sensitivity studies projects for Procter & Gamble (P&G). The final results and findings were presented with success to the customers of SKIM in two sequential presentations in Geneva in 2009."
    },
    {
      yearRange: "2004 - 2007",
      title: "Assistant Accountant",
      place: "Kallianis Compania Naviera S.A., Shipping & Investment",
      desc: "Prepare and process purchase orders, invoices, cheques, and contract Payments. Ensure purchase orders and check invoices. Responsible for processing and maintenance of encumbrances. Provide guidance regarding purchasing and financial standard practices to colleague personnel. Responsible for ensuring compliance with standard practices. Recommend changes to purchasing and other financial policies and practices. Responsible for managing year-end cut-off processes for Accounts Payable"
    },
  ];
  const educationDetails = [
    {
      yearRange: "2007 - 2010",
      title: "MSc. in Econometrics",
      place: "Erasmus University",
      desc: "Thesis: Meta Analysis in 4 years Conjoint Studies of Price Sensitivity in the retail industry",
    },
    {
      yearRange: "2005 - 2007",
      title: "MSc. in Financial Engineering & Management",
      place: "Aegean University",
      desc: "Thesis: Oil prices market analysis using nonlinear modeling: A neural network application",
    },
    {
      yearRange: "2000 - 2005",
      title: "BA in Financial Engineering & Management",
      place: "Aegean University",
      desc: ""
    }
  ];

  const coursesDetails = [
    {
      year: "2023",
      title: "Data Monetization Strategy",
      place: "MIT Sloan School of Management",
    },
    {
      year: "2021",
      title: "Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
      place: "DeepLearning.AI",
    },
    {
      year: "2020",
      title: "Digital Innovation",
      place: "University of Virginia / BCG",
    },
    {
      year: "2020",
      title: "Impact from Digital Transformation",
      place: "EIT Digital / European Union",
    },
    {
      year: "2019",
      title: "Oxford Blockchain Strategy",
      place: "Oxford University / Said Business School",
    },
    {
      year: "2019",
      title: "Complete Guide to TensorFlow for Deep Learning with Python",
      place: "Udemy",
    },
    {
      year: "2018",
      title: "Data Science & Blockchain",
      place: "Blockchain Training Alliance",
    },
    {
      year: "2018",
      title: "Python and Django Full Stack Web Developer Bootcamp",
      place: "Udemy",
    },
    {
      year: "2018",
      title: "Using Python to Access Web Data",
      place: "University of Michigan",
    },    
    {
      year: "2017",
      title: "Data anonymization and GDPR",
      place: "Udemy",
    },
    {
      year: "2016",
      title: "Executive Data Science Specialization",
      place: "Johns Hopkins University",
    },
    {
      year: "2016",
      title: "Leadership program on big data and business analytics",
      place: "Erasmus University of Rotterdam",
    },
    {
      year: "2016",
      title: "Official Spark Training",
      place: "Spark Institute/ Databricks",
    },
    {
      year: "2016",
      title: "Developing Data Products, ",
      place: "Johns Hopkins University ",
    },
    {
      year: "2016",
      title: "Applied Data Science with Python",
      place: "University of Michigan",
    },     
    {
      year: "2015",
      title: "Introduction to Big Data",
      place: "University of California San Diego",
    },
    {
      year: "2015",
      title: "Machine Learning Foundations",
      place: "University of Washington",
    },
    {
      year: "2015",
      title: "Hadoop Platform and Application Framework",
      place: "University of California San Diego",
    },
    {
      year: "2015",
      title: "Smart Device & Mobile Emerging Technologies",
      place: "Yonsei University",
    },
    {
      year: "2015",
      title: "Neo4j Introduction Workshop",
      place: "Neo4j institution",
    },
    {
      year: "2014",
      title: "Qlikview, Internal training",
      place: "Qlikview Institute",
    },
    {
      year: "2013",
      title: "Networked Life",
      place: "University of Pennsylvania",
    },   
    {
      year: "2012",
      title: "Advanced Analytics for Customer Intelligence using SAS",
      place: "SAS Institute",
    },
  ];

  const programming_skills = [
    {
      name: "Python Full Stuck",
      percent: 90,
    },    
    {
      name: "Spark/ Hadoop/ Hive/ Beam/ Flink",
      percent: 90,
    },
    {
      name: "Databricks Ecosystem",
      percent: 90,
    },
    {
      name: "MLFLOW",
      percent: 90,
    },
    {
      name: "Visualization (Tableau, PowerBI)",
      percent: 80,
    },
    {
      name: "Knime",
      percent: 80,
    },
    {
      name: "SAS(Base, Enterprize Miner)",
      percent: 80,
    },
    {
      name: "Django Full Stack",
      percent: 70,
    },  
    {
      name: "Airflow",
      percent: 70,
    },
    {
      name: "R",
      percent: 70,
    },  
    {
      name: "Graph Databases (NetworkX, Neo4j)",
      percent: 70,
    },
    {
      name: "Cloud (AWS, Azure)",
      percent: 60,
    },

    {
      name: "Postgres/ Mysql/ SQL Server/ MongoDB/ InfluxDB + more",
      percent: 60,
    },
    {
      name: "JSON/ CSS/ HTML",
      percent: 50,
    },
    {
      name: "Reactjs/ Bootstrap",
      percent: 40,
    },
 
  ];

  const framework_skills = [
    {
      name: "ML Model development (Supervised & unsupervised learning)",
      percent: 90,
    },
    {
      name: "CRISP-DM & ML Lifecycle Management",
      percent: 90,
    },
    {
      name: "Customer Analytics (CLV, Retention, Customer 360)",
      percent: 90,
    },
    {
      name: "Customer Profiling & Segmentation",
      percent: 90,
    },
    {
      name: "Campaign Marketing Optimization",
      percent: 90,
    },
    {
      name: "Agile Project Management",
      percent: 90,
    },    
    {
      name: "Experimental Design",
      percent: 80,
    },
    {
      name: "Choice Based models/ Conjoint analysis",
      percent: 80,
    },
    {
      name: "Price Sensitivity Models",
      percent: 80,
    },
    {
      name: "MLOps Strategy & Implementation",
      percent: 80,
    },
    {
      name: "Graph Databases (NetworkX, Neo4j)",
      percent: 70,
    },
    {
      name: "Natural Language Process/ Topic Modeling",
      percent: 70,
    },
    {
      name: "Anomaly Detection",
      percent: 60,
    },
    {
      name: "Design Thinking",
      percent: 60,
    },
  ];



  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
            {/* My Seminars */}
            
            <div className="col-lg-6 wow fadeInUp">
              <h2 className="text-7 fw-600 mb-4 pb-2">My Seminars</h2>
              <div className="border-start border-2 border-primary ps-3">
                {coursesDetails.length > 0 &&
                  coursesDetails.map((value, index) => (
                    <div key={index}>
                      <h3 className="text-5">{value.title}</h3>
                      <p className="mb-2">
                        {value.place} / {value.year}
                      </p>
                      <hr className="my-4" />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* My Programming Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Programming Skills</h2>
        <div className="row gx-5">
          {programming_skills.length > 0 &&
            programming_skills.map((programming_skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {programming_skill.name}{" "}
                  <span className="float-end">{programming_skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: programming_skill.percent + "%" }}
                    aria-valuenow={programming_skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* My Framework Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Framework Skills</h2>
        <div className="row gx-5">
          {framework_skills.length > 0 &&
            framework_skills.map((framework_skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {framework_skill.name}{" "}
                  <span className="float-end">{framework_skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: framework_skill.percent + "%" }}
                    aria-valuenow={framework_skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
