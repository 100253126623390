import React from "react";

const AboutUs2 = () => {
  const email = "tkachrimanis@protonmail.com"; // Define the email address

  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm Tasos
              <span className="fw-700 border-bottom border-3 border-primary"></span>
            </h2>
            <p className="text-4">
              {/* Use the CSS class 'word-wrap' to allow wrapping for the email address */}
              a dynamic leader with extensive hands-on expertise in machine learning, data science, AI, and cloud computing. My career has been at the intersection of technology and innovation, where I've thrived in the midst of the digital revolution, staying abreast of the latest developments in these fields. My background is diverse, encompassing quantitative, business, and management skills, and I have successfully applied my knowledge across various industries including Logistics, FinTech, Media, Telco, Manufacturing, and Retail. I am a collaborative team player with strong leadership abilities, capable of taking data-driven projects from concept to execution. Over the past five years, my primary focus has been to lead AI initiatives for clients, managing multidisciplinary teams, and working closely with business leaders to shape AI strategic roadmaps, innovate through IP solutions, and harness the latest advancements in MLOps. In addition to my leadership roles, I have a track record of recruiting and nurturing talent in the form of data scientists and data engineers. Furthermore, I am dedicated to training and educating senior consultants, Directors, and VPs on the vast potential of the AI era. In summary, I bring a unique blend of technical expertise, leadership, and a passion for innovation to organizations, making me an ideal candidate for growth-oriented companies that want to embark on the innovation train.
              <br />
              
            </p>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp" data-wow-delay="0.2s">
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  12
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experience</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Tasos Kachrimanis</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              {/* Apply the 'word-wrap' class to the email address */}
              <a className="link-dark word-wrap" href={`mailto:${email}`}>
                {/* Apply the 'word-wrap' class to the email address */}
                <span className="word-wrap">{email}</span>              
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">27 June 1982</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Greece</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs2;
